/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import $ from 'jquery';
import Headroom from 'headroom.js';
import Swiper from 'swiper/dist/js/swiper.js';
import './modernizr-custom.js';

$(document).ready(async function () {

	// Responsive menu
	$('header').each(function () {
		var element = $(this),
			body = $(document.body),
			responsiveNav = $('.responsive-nav');

		var toggler = $('.toggler').click(function () {
			responsiveNav.toggleClass('-open');
			toggler.toggleClass('-closed');
			toggler.find('.hamburger').toggleClass('is-active');
		});
	});

	// Header affix
	$('.toggler').each(async function () {
		var headroom = new Headroom(this);
		headroom.init();
	});

	// Match height
	async function matchHeights() {
		await import('jquery-match-height');

		var element = $(this);
		$('.match-height').matchHeight({
			property: 'min-height'
		});
	};

	if ($('.match-height')[0]) {
		matchHeights();
	}

	// Slideshow
	$('.frontpage').each(async function () {
		await import('fullpage.js');

		if ($('.no-objectfit')[0]) {
			$('.frontpage-slider__slide').each(function () {
				var element = $(this);
				var imageContainers = element.find('.frontpage-slider__image-container');

				$.each(imageContainers, function () {
					var imageContainer = $(this);
					var image = imageContainer.find('.frontpage-slider__image');
					imageContainer.css({ 'background-size': 'cover', 'background-image': 'url(' + image.attr('src') + ')' });
					imageContainer.find('picture').remove();
				});
			});
		}

		$('#fullpage').fullpage({
			navigation: true,
			scrollingSpeed: 1600,
			onLeave: function (index, nextIndex, direction) {
				var slides = $('.fp-section');

				//console.log($(slides[nextIndex - 1]))

				if (direction === 'down') {
					$(document.body).removeClass('up');
					$(document.body).addClass('down');
				}
				else {
					$(document.body).removeClass('down');
					$(document.body).addClass('up');
				}

				if ($(slides[index - 1]).hasClass('weddings')) {
					$(document.body).removeClass('weddings')
				}

				if ($(slides[index - 1]).hasClass('events')) {
					$(document.body).removeClass('events')
				}

				if ($(slides[nextIndex - 1]).hasClass('weddings')) {
					$(document.body).addClass('weddings')
				}

				if ($(slides[nextIndex - 1]).hasClass('events')) {
					$(document.body).addClass('events')
				}
			}
		});
	});

	// Slider in frontpage slider
	$('.slideshow').each(async function () {

		$(document.body).addClass('-hide-overflow');

		var mySwiper = new Swiper('.slideshow.swiper-container', {
			loop: true,
			grabCursor: true,
			effect: 'fade',
			slidesPerView: 'auto',
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			}
		})
	});

	// Lightbox
	var lightbox;
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		//await import('lg-video');

		lightbox = $(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Paged images
	var imageGallery = $('.image-gallery');
	if (imageGallery.length) {
		await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/jquery.waypoints.js');
		await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/shortcuts/infinite.js');

		imageGallery.each(function () {
			var images = $(this);
			images.find('a[rel=next]').one('click', function (e) {
				var infinite = new Waypoint.Infinite({
					element: images[0],
					container: images.find('.columns'),
					items: '.column',
					more: 'a[rel=next]',
					offset: function () {
						return Waypoint.offsetAliases['bottom-in-view'].call(this) + 200;
					},
					onAfterPageLoad: function (items) {
						// Fade in added items
						const delay = 40;
						items.css('opacity', 0).each(function (i) {
							var item = $(this);
							setTimeout(function () {
								item.css({
									opacity: 1,
									transition: 'opacity ease 120ms'
								});
							}, i * delay);
						});

						$('body:has([rel=lightbox])').each(async function () {
							await import('lightgallery');

							lightbox.data('lightGallery').destroy(true);

							$(this).lightGallery({
								selector: '[rel=lightbox]',
								download: false,
								thumbnail: false,
								fullScreen: false,
								actualSize: false,
								hash: false
							});
						})
					}
				});

				images.find('.pagination-container').hide();
				e.preventDefault();
			});
		});
	}

	// Route form
	$('.route-form').each(function () {
		var element = $(this);
		var button = element.find('button');
		var destinationAddress = element.find('input[type=text]');
		var destination = element.find('#To');

		// Route on button click
		button.click(function (e) {
			var route = {
				saddr: destinationAddress.val(),
				daddr: destination.val()
			};

			window.open('https://maps.google.com/?' + $.param(route), '_blank');

			e.preventDefault();
		});
	});

	// Google Maps 
	$('.google-maps').each(async function () {
		var maps = $(this),
			apiKey = maps.data('api-key'),
			dataGeolocations = maps.data('geolocations').split(';');

		const loadGoogleMapsApi = (await import(/* webpackChunkName: "load-google-maps-api" */ 'load-google-maps-api')).default;
		const googleMaps = await loadGoogleMapsApi({
			key: apiKey
		});

		var bounds = new google.maps.LatLngBounds();

		var map = new googleMaps.Map(maps[0], {
			zoom: 17,
			minZoom: 2,
			zoomControl: true,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: true,
			styles: [{ "featureType": "landscape.natural", "elementType": "geometry.fill", "stylers": [{ "visibility": "on" }, { "color": "#e0efef" }] }, { "featureType": "poi", "elementType": "labels", "stylers": [{ visibility: "off" }] }, { "featureType": "poi", "elementType": "geometry.fill", "stylers": [{ "visibility": "on" }, { "hue": "#1900ff" }, { "color": "#c0e8e8" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "lightness": 100 }, { "visibility": "simplified" }] }, { "featureType": "road", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "visibility": "on" }, { "lightness": 700 }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#7dcdcd" }] }],
		});

		var image = {
			url: require('../images/marker-icon.png').default,
			scaledSize: new google.maps.Size(149, 45),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(75, 45)
		};

		for (var i = 0; i < dataGeolocations.length; i++) {
			var latLng = dataGeolocations[i].split(',');

			var marker = new google.maps.Marker({
				position: {
					lat: parseFloat(latLng[0]),
					lng: parseFloat(latLng[1])
				},
				map: map,
				icon: image
			});

			bounds.extend(marker.position);
		}

		map.fitBounds(bounds);
		var changeBoundsListener = google.maps.event.addListener(map, "idle", function () {
			if (map.getZoom() > 17) map.setZoom(17);
			google.maps.event.removeListener(changeBoundsListener);
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});
});